// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Brand themes
.brand-dark {
  @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Light
@import './_metronic/_assets/sass/themes/layout/aside/light.scss';

:root {
  --heylight-primary: #ea1c00;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;

  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

a.slate-a {
  text-decoration: underline;
}

li.slate-li {
  margin-bottom: 0.5rem;
}

.no-wrap {
  white-space: nowrap;
}

.toastContainer {
  position: fixed;
  padding-top: 47px;
  top: 0;
  right: 0;
  z-index: 0;
  max-width: 300px;

  .MuiSnackbar-root {
    position: relative;
    white-space: pre-line;
  }

  .MuiSnackbarContent-root {
    flex-wrap: nowrap;
  }

  .MuiSnackbarContent-message {
    word-break: break-word;
  }
}

.payment-schedule {

  th, td {
    text-align: right;
  }
  td {
    font-family: monospace;
    &:has(span.schedule-version-new-entry) {
      font-weight: bold;
      background-color: rgba(255, 152, 0, 0.2);
    }
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: #fff;
    border: #fff;
  }

  tbody tr{
      border-left: solid #fff;
      border-right: solid #fff;
      border-top: solid #fff;
      &:hover {
        border: solid #767676;
      }
    
    &.future-entry {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
      &:hover {
        border-color: #767676;
      }
      td {
        font-style: italic;
        color: #333333;
      }
      td:first-child {
        background-color: #f2f2f2;
      }
    }
  }

  tbody tr:last-of-type {
    border-bottom: solid #fff;
  }

  .amount-due-entry {
    font-weight: bold;
  }
}
