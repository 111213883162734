// .heyLight rebranding overrides
.heyLight {
  .tab-content a {
    color: $heylight;
  }

  &.scrolltop {
    background-color: $heylight;
  }

  a.text-hover-primary:hover,
  .text-hover-primary:hover {
    color: $heylight-hover !important;
  }

  .EWDLD:focus {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px inset,
      #ea1c0066 0px 0px 0px 0.2rem !important;
  }

  .nav {
    .show > .nav-link,
    .nav-link:hover:not(.disabled),
    .nav-link.active {
      color: $heylight;

      .nav-text,
      .nav-icon i,
      &:after {
        color: $heylight;
      }
    }
  }
}
